import React from 'react'
import { SEO } from '@renderbus/common/components'
import Layout from '../molecules/layout'
import {
  XgtnzhdContent,
  GuideWrapper,
  GuideTitle,
  GuideContent,
  GuideItem,
  GuideTop,
  RegisterButton,
  FirstWrapper,
  FirstContent,
  FirstGift,
  Instruction,
} from './ysdh-xrj-lqzn.atom'
import Left from '../images/ysdh-xrj-lqzn/left.png'
import Right from '../images/ysdh-xrj-lqzn/right.png'
import Guide01 from '../images/ysdh-xrj-lqzn/guide01.png'
import Guide02 from '../images/ysdh-xrj-lqzn/guide02.png'
import Guide03 from '../images/ysdh-xrj-lqzn/guide03.png'
import Guide04 from '../images/ysdh-xrj-lqzn/guide04.png'
import Guide05 from '../images/ysdh-xrj-lqzn/guide05.png'
import Check from '../images/ysdh-xrj-lqzn/check.png'
import { AnimationExtremeSpeedBanner } from '../molecules/banner'
import { graphql } from 'gatsby'

const GuideList = [
  {
    img: Guide01,
    alt: '注册瑞云渲染动画账号领10元无门槛渲染劵',
    welfare: ['<span>10元</span>无门槛渲染券', '<span>7天五折</span>渲染权益'],
    title: '注册后立即到账',
    subTitle: '云渲染7X24h在线技术支持',
  },
  {
    img: Guide02,
    alt: '实名认证瑞云动画账号领50元无门槛渲染劵',
    welfare: ['<span>50元</span>无门槛渲染券'],
    title: '实名认证福利',
    subTitle: '保障数据资产安全',
  },
  {
    img: Guide03,
    alt: '绑定瑞云渲染小程序领30元无门槛渲染劵',
    welfare: ['<span>30元</span>无门槛渲染券'],
    title: '绑定小程序福利',
    subTitle: '随时随地监控作业进度',
  },
  {
    img: Guide04,
    alt: '关注瑞云渲染公众号领10元无门槛渲染劵',
    welfare: ['<span>10元</span>无门槛渲染券'],
    title: '关注公众号福利',
    subTitle: '网罗最新行业资讯动态',
  },
  {
    img: Guide05,
    alt: '邀请好友注册瑞云渲染账号领渲染劵',
    welfare: ['<span>更多</span>无门槛优惠券'],
    title: '好友邀请福利',
    subTitle: ['<div>受邀好友注册首充</div><div>双方各得10元渲染券</div>'],
  },
]

class AnimationActivity extends React.PureComponent {
  render() {
    const {
      location,
      data,
      pageContext: { topThreeShare },
    } = this.props
    return (
      <Layout location={location} topThreeShare={topThreeShare}>
        <SEO
          title='瑞云影视动画:新用户注册+首充1元,立领200元无门槛渲染券-瑞云渲染'
          keywords='瑞云渲染动画注册福利,瑞云影视动画新人专享福利,瑞云渲染动画首充福利'
          description='Renderbus瑞云渲染一家专业的影视动画云渲染农场，现推出新人专属福利！新用户注册瑞云动画账号，即赠100元渲染券，首充1元再得100元渲染券，共200元无门槛渲染券，助您轻松享受专业云渲染服务。'
        />
        <AnimationExtremeSpeedBanner isBanner={false} />
        <XgtnzhdContent>
          <FirstWrapper>
            <GuideTitle>
              <img src={Left} alt='' />
              <span>首充福利</span>
              <img src={Right} alt='' />
            </GuideTitle>
            <FirstContent>
              <FirstGift fluid={data.firstGift.childImageSharp.fluid} />
            </FirstContent>
            <Instruction>活动规则：自注册起7天内充值1元，额外赠送100元通用渲染券</Instruction>
          </FirstWrapper>
          <GuideWrapper>
            <GuideTitle>
              <img src={Left} alt='' />
              <span>注册即享</span>
              <img src={Right} alt='' />
            </GuideTitle>
            <GuideContent>
              {GuideList.map((item, index) => (
                <GuideItem key={index}>
                  <GuideTop>
                    <div>
                      {item.welfare.map((i, ind) => (
                        <div key={ind} className='guide-item'>
                          <img src={Check} alt='' />
                          <div
                            className='welfare-text'
                            dangerouslySetInnerHTML={{ __html: i }}
                          ></div>
                        </div>
                      ))}
                    </div>
                  </GuideTop>
                  <div className='guide-img'>
                    <img src={item.img} alt={item.alt} />
                  </div>
                  <p className='title'>{item.title}</p>
                  <div
                    className='sub-title'
                    dangerouslySetInnerHTML={{ __html: item.subTitle }}
                  ></div>
                </GuideItem>
              ))}
            </GuideContent>
            <RegisterButton href='https://task.renderbus.com/sso/register'>
              立即注册，免费渲染
            </RegisterButton>
          </GuideWrapper>
        </XgtnzhdContent>
      </Layout>
    )
  }
}

export const query = graphql`
  query {
    firstGift: file(relativePath: { regex: "/ysdh-xrj-lqzn/first-gift.png/" }) {
      ...fluidImage
    }
  }
`

export default AnimationActivity
